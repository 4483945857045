import { ThunderboltOutlined } from '@ant-design/icons';
import { Form, FormItemProps, Space } from 'antd';
import { NamePath } from 'rc-field-form/es/interface';
import { FC } from 'react';

import EnhancedForm from '../index';

type SyncedEnhancedFormItemProps = Omit<FormItemProps, 'name'> & {
  name: NamePath;
};

const SyncedEnhancedFormItem: FC<SyncedEnhancedFormItemProps> = ({
  name,
  initialValue,
  label,
  ...rest
}) => {
  const form = Form.useFormInstance();

  const value = EnhancedForm.useLocalWatch(name, form, {
    defaultValue: initialValue,
  });

  return (
    <Form.Item
      initialValue={value}
      label={
        <Space align="center" size={4}>
          {label}
          <ThunderboltOutlined style={{ fontSize: 10 }} />
        </Space>
      }
      name={name}
      {...rest}
    />
  );
};

SyncedEnhancedFormItem.displayName = 'SyncedEnhancedFormItem';

export default SyncedEnhancedFormItem;
