import { ReloadOutlined } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import { useRequest } from 'ahooks';
import { Button, Result } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { createContext, FC, PropsWithChildren, useContext } from 'react';

import Loading from '../Loading';

type AppConfig = {
  analyticsId: string;
  datagonUrl: string;
  environment: 'testing' | 'production';
  hotjarId: string;
  intercomId: string;
};

export const ConfigContext = createContext<AppConfig | undefined>(undefined);

export const useConfig = (): AppConfig => {
  const config = useContext(ConfigContext);
  if (!config) {
    throw new Error('No config');
  }

  return config;
};

const ConfigProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const {
    data: config,
    error,
    loading,
  } = useRequest<AppConfig, []>(
    () => {
      return axios(`/config.json?cache=${CACHE}`).then(
        (res: AxiosResponse<AppConfig>) => res.data,
      );
    },
    {
      onError: (error) => {
        captureException(error);
      },
    },
  );

  if (error) {
    return (
      <Result
        extra={
          <Button
            href={window.location.href}
            icon={<ReloadOutlined />}
            type="primary"
          >
            Reload
          </Button>
        }
        status={500}
        subTitle="Error during config fetch"
        title="Error"
      />
    );
  }

  if (loading && !config) {
    return <Loading />;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

ConfigProvider.displayName = 'ConfigProvider';

export default ConfigProvider;
