import { useLocalStorageState } from 'ahooks';
import { Options } from 'ahooks/es/createUseStorageState';
import { Form, FormInstance, FormProps, Spin } from 'antd';
import { NamePath, Store } from 'rc-field-form/es/interface';
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

import Item from './Item';
import SyncedItem from './SyncedItem';

export type EnhancedFormProps<T> = Omit<
  FormProps<T>,
  'initialValues' | 'children'
> & {
  resetOnInitialize?: boolean;
  initialValues?: Partial<T>;
  loading?: boolean;
  children?: ((values: T, form: FormInstance) => JSX.Element) | ReactNode;
};

export type EnhancedFormStateProps = {
  pristine: boolean;
  dirty: boolean;
};

export const EnhancedFormStateContext = createContext<EnhancedFormStateProps>({
  dirty: false,
  pristine: true,
});

const useEnhancedFormState = () => {
  return useContext(EnhancedFormStateContext);
};

const useLocalWatch = <ValueType = Store,>(
  dependencies: NamePath,
  form: FormInstance,
  options: Options<ValueType>,
) => {
  const formValue = Form.useWatch<ValueType>(dependencies, form);
  const { path } = useRouteMatch();

  const [value, setValue] = useLocalStorageState(
    String(
      [
        path.replace('/', '').replaceAll('/', '-'),
        Array.isArray(dependencies) ? dependencies.join('/') : dependencies,
      ].join('-'),
    ),
    options,
  );

  useEffect(() => {
    if (Array.isArray(formValue) ? formValue.length : formValue) {
      setValue(formValue);
    }
  }, [formValue, setValue]);

  return value;
};

const EnhancedForm = <T extends object>({
  form: formProp,
  loading = false,
  initialValues,
  children,
  ...rest
}: EnhancedFormProps<T>): ReactElement => {
  const [pristine, setPristine] = useState(true);
  const [formInstance] = Form.useForm();

  const form = useMemo(
    () => formProp || formInstance,
    [formInstance, formProp],
  );

  const values = Form.useWatch<T>([], form);

  // https://github.com/ant-design/ant-design/issues/22372#issuecomment-602102164
  useEffect(() => {
    if (initialValues && !loading) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      form.setFieldsValue(initialValues);
      form.resetFields();
      setPristine(true);
    }
  }, [form, initialValues, loading]);

  return (
    <Spin spinning={loading}>
      <EnhancedFormStateContext.Provider
        value={{
          dirty: !pristine,
          pristine,
        }}
      >
        <Form<T>
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          onSubmitCapture={() => {
            setPristine(true);
          }}
          onValuesChange={() => {
            setPristine(false);
          }}
          scrollToFirstError
          {...rest}
        >
          {typeof children === 'function' ? children(values, form) : children}
        </Form>
      </EnhancedFormStateContext.Provider>
    </Spin>
  );
};

EnhancedForm.useEnhancedFormState = useEnhancedFormState;
EnhancedForm.useLocalWatch = useLocalWatch;
EnhancedForm.Item = Item;
EnhancedForm.SyncedItem = SyncedItem;

EnhancedForm.displayName = 'EnhancedForm';

export default EnhancedForm;
